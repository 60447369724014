import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import LoaderSectionMainPage from './Components/LoaderComponents/LoaderSectionMainPage';

// Lazy load major components
const App = React.lazy(() => import('./App'));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Suspense fallback={<LoaderSectionMainPage />}>
      <App />
    </Suspense>
  </BrowserRouter>
);

// Update web vitals reporting
reportWebVitals(metric => {
  // Send to analytics
  console.log(metric);
});

// Add after imports
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js');
  });
}
